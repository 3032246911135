import { UseQueryResult } from "@tanstack/react-query";
import { TimeoutError } from "ky";
import { Loader2 } from "lucide-react";
import { useI18nContext } from "../../i18n/i18n-react";
import { ApiError } from "../../lib/service";
import { Button } from "../ui/button";
import { useNavigateBack } from "../../lib/hook/useNavigateBack";

export const ErrorRetry = ({
  retry,
  err,
}: {
  retry: () => void;
  err?: unknown;
}) => {
  const { LL } = useI18nContext();
  const back = useNavigateBack();

  let text = LL.error.unknown();

  const isNotFound = err instanceof ApiError && err.code === 404;
  if (isNotFound) text = LL.error.notFound();

  if (err && err instanceof TimeoutError) text = LL.error.timeout();

  return (
    <div className="flex h-full grow flex-col items-center justify-around px-6">
      <div>{text}</div>

      {isNotFound ? (
        <Button onClick={retry}>{LL.back()}</Button>
      ) : (
        <Button onClick={back}>{LL.retry()}</Button>
      )}
    </div>
  );
};

export type QueryResult<T> = Pick<
  UseQueryResult<T, unknown>,
  "status" | "error"
> & {
  refetch?: () => void;
};

export const LoaderLayout = <T,>({
  queries,
  children,
}: {
  queries: (QueryResult<T> | undefined)[] | QueryResult<T>;
  children?: React.ReactNode;
}) => {
  const finalQueries = Array.isArray(queries)
    ? queries.filter(Boolean)
    : [queries];

  const status = finalQueries.some((query) => query.status === "error")
    ? "error"
    : finalQueries.some((query) => query.status === "pending")
      ? "pending"
      : "success";
  const errors = finalQueries.map((query) => query.error).filter(Boolean);
  const error = errors?.[0];
  const refetch = () => {
    finalQueries
      .filter((query) => query.status === "error")
      .forEach((query) => query.refetch?.());
  };

  switch (status) {
    case "pending":
      return (
        <div className="flex-center h-full grow">
          <Loader2 className="animate-spin" />
        </div>
      );
    case "error":
      return <ErrorRetry retry={refetch} err={error} />;
    case "success":
      return children;
  }
};
