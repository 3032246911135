import ky from "ky";
import { env } from "./env";
import {
  Authorization,
  Config,
  Profile,
  RouteCard,
  RouteInstrumentRequest,
  Order,
  CreateOrderRequest,
  RouteQuote,
  RouteQuoteRequest,
  Preorder,
} from "./types";
import { getAuth, removeAuth } from "./local";
import z from "zod";
import * as Sentry from "@sentry/react";

const RESPONSE_SCHEMA = z.union([
  z.object({
    type: z.literal("error").default("error"),
    error: z.object({
      code: z.number(),
      description: z.string(),
      extra: z.unknown().optional(),
    }),
  }),
  z.object({
    type: z.literal("data").default("data"),
    data: z.unknown().nullable(),
  }),
]);

export class ApiError extends Error {
  constructor(
    code: number,
    description?: string,
    extra?: unknown,
    request?: Request,
  ) {
    super(`code: ${code}, description: ${description}, request: ${request}`);
    this.name = "ApiError";
    this.code = code;
    this.description = description;
    this.extra = extra;
  }
  readonly code: number;
  readonly description?: string;
  readonly extra?: unknown;
}

const client = ky.create({
  prefixUrl: env.VITE_ROUTE_SERVER + "/",
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
  hooks: {
    beforeRequest: [
      (req) => {
        const token = getAuth()?.token;
        if (token) {
          req.headers.set("Authorization", `Bearer ${token}`);
        }
      },
    ],
    afterResponse: [
      async (req, _, res) => {
        const json = await res.json();
        const rsp = RESPONSE_SCHEMA.safeParse(json);
        if (!rsp.success)
          throw new ApiError(500, "Invalid response", rsp.error);
        if (rsp.data.type === "error") {
          const error = rsp.data.error;
          if (error.code === 401) {
            removeAuth();
            if (window.location.pathname.startsWith("/auth")) return;
            window.location.replace("/auth");
          }
          throw new ApiError(error.code, error.description, error.extra, req);
        }

        return new Response(JSON.stringify(rsp.data.data));
      },
    ],
    beforeError: [
      (error) => {
        Sentry.captureException(error);
        return error;
      },
    ],
  },
});

export const auth = (code: string) =>
  client.post("auth", { json: { code } }).json<Authorization>();
export const getProfile = () =>
  client.get("profile?version=1.6.0").json<Profile>();
export const getConfig = () => client.get("config").json<Config>();
export const getQuote = (req: RouteQuoteRequest) =>
  client.post("quote", { json: req }).json<RouteQuote>();
export const getInstruments = () =>
  client.get("checkout/instruments").json<RouteCard[]>();
export const removeInstrument = (id: string) =>
  client.delete(`checkout/instruments/${id}`).json();
export const addInstrument = (req: RouteInstrumentRequest) =>
  client.post("checkout/instruments", { json: req }).json<RouteCard>();
export const addOrder = (req: CreateOrderRequest) =>
  client.post(`orders`, { json: req }).json<Order>();
export const getOrder = (id: string) =>
  client.get(`orders/${id}`).json<Order>();
export const pay = (orderId: string, deviceSessionId: string) =>
  client
    .post(`orders/${orderId}/payment`, {
      json: {
        device_session_id: deviceSessionId,
      },
    })
    .json<Order>();
export const updateOrderPrice = (orderId: string, assetAmount: string) =>
  client
    .post(`orders/${orderId}/price`, {
      json: { asset_amount: assetAmount },
    })
    .json<Order>();

export const getOrders = (limit: number, offset: string) =>
  client.get("orders", { searchParams: { limit, offset } }).json<Order[]>();

export const sumsubToken = () =>
  client.get("kyc/token").json<{
    token: string;
    state: "initial" | "pending" | "success" | "retry" | "blocked" | "ignore";
  }>();

export const preorder = (preorderReq: Preorder) =>
  client
    .post("preorder", {
      json: {
        ...preorderReq,
        amount: preorderReq.amount ? preorderReq.amount + "" : undefined,
      },
    })
    .json<{ authorization_token: string }>();
