import type { BaseTranslation } from "../i18n-types.js";

const en = {
  pay: "Pay",
  received: "Received",
  home: {
    payLimit: "Min {minimum} {currency}, Max {maximum} {currency}",
    previewOrder: "Preview Order",
    selectAToken: "Select a token",
    selectACurrency: "Select a currency",
    selectACard: "Select a card",
    addDebitOrCreditCard: "Add Debit or Credit Card",
    inputCard: {
      cardNumber: "Card Number",
      expiryDate: "Expiry Date",
      securityCode: "Security Code",
      holder: "Cardholder Name",
      action: "Add card",
    },
    buy: "Buy {symbol:string}",
  },
  bankTransferInstruction: {
    title: "Bank Transfer Instruction",
    menuName: "Bank Transfer",
    desc: [
      "Please confirm that the name on your bank account matches the KYC information.",
      "Please copy the above information to your bank transfer slip.",
    ],
    sections: {
      beneficiary: {
        title: "Beneficiary",
        items: {
          remarks: {
            title: "Remarks",
            desc: "This code is used to identify your transfer, please be sure to add it.",
          },
          IBAN: "IBAN",
          accountName: "Account Name",
          accountNumber: "Account Number",
          address: "Address",
        },
      },
      beneficiaryBank: {
        title: "Beneficiary Bank",
        items: {
          BIC_SWIFTCode: "BIC / SWIFT Code",
          bankName: "Bank Name",
          bankAddress: "Bank Address",
          bankCity: "Bank City",
          bankCountry: "Bank Country",
        },
      },
    },
  },
  payment: {
    method: "Payment Method",
    totalToken: "Total Token",
    price: "Price",
    feeByGateway: "Fee by Gateway",
    feeByMixin: "Fee by Mixin",
    totalAmount: "Total Amount",
    tips: "You may be charged additional fees by your bank, please contact your bank for details.",
  },
  login: {
    action: "Login with Mixin",
    actionWithPreorder: "Authorization",
  },
  order: {
    detail: "Order Detail",
    payment: {
      fetching: "Fetching order",
      failed: "Payment failed",
      paying: "Paying",
      success: "Payment completed",
      successDesc:
        "After the payment is completed, the token will be transferred directly to your wallet.",
      expired: "Price expired",
    },
    status: {
      created: "Created",
      paying: "Pending",
      success: "Success",
      failed: "Failed",
      returnTo: "Return to {name:string}",
    },
    useLatestPrice: "Use latest price",
  },
  orders: {
    title: "Orders",
    boughtSome: "Bought {symbol:string}",
  },
  card: {
    visa: "Visa",
    mastercard: "MasterCard",
  },
  kyc: "KYC",
  status: "Status",
  name: "Mixin Route",
  slogan: "Instantly buy cryptocurrency to your wallet",
  delete: "Delete",
  done: "Done",
  cancel: "Cancel",
  retry: "Retry",
  back: "Back",
  copied: "Copied",
  error: {
    10608: "Cardholder name not match your name.",
    10610: "Inconsistency country failed, please contact customer service.",
    addCard: "Failed to add card.",
    quote: "Failed to get the latest quote, maybe the price has expired.",
    auth: "Failed to authenticate, please try again.",
    kyc: {
      blocked:
        "Failed to verify your identity, please contact customer service.",
    },
    pay: "Failed to pay, please try again.",

    unknown: "Unknown error",
    notFound: "Not Found",
    timeout: "Network timeout",
    params: "Invalid parameters",

    copy: "Failed to copy to clipboard",
  },
} satisfies BaseTranslation;

export default en;
