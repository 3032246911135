import { Outlet } from "react-router-dom";
import { Suspense } from "react";
import { Loader2 } from "lucide-react";

export const Layout = () => {
  return (
    <>
      <Suspense
        fallback={
          <main className="flex-center">
            <Loader2 className="animate-spin" />
          </main>
        }
      >
        <Outlet />
      </Suspense>
    </>
  );
};
